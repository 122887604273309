body {
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	background: #fff;
	color: #636861;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: #112b18;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #112b18;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

h3 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #112b18;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 700;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #112b18;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-weight: 700;
}

h5 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #112b18;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

h6 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #112b18;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 10px;
}

a,
a:visited {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-weight: 600;
	text-decoration: none;
}

a:hover {
	color: #aaa;
	text-decoration: underline;
}

ul {
	margin-top: 0.25rem;
	margin-bottom: 0rem;
	padding-left: 30px;
}

ol {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 30px;
}

label {
	display: block;
	margin-bottom: 0px;
	color: #000;
	font-weight: 700;
}

blockquote {
	margin-bottom: 10px;
	padding: 10px 20px;
	border-left: 5px solid #89997c;
	font-size: 1.15rem;
	line-height: 1.75rem;
}

.button {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 20px;
	margin-bottom: 0.25rem;
	padding: 0.375rem 1rem;
	background-color: #3169b4;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
}

.button:hover {
	background-color: #525252;
	color: #fff;
	text-decoration: none;
}

.button.header-search-button {
	width: 25%;
	height: 50px;
	margin-top: 0rem;
	margin-right: 0px;
	margin-bottom: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
}

.button.secondary-button {
	background-color: #797676;
}

.button.secondary-button:hover {
	background-color: #525252;
}

.nav-section {
	position: relative;
	background: linear-gradient(120deg, #0e1a07, #1d3c21);
	box-shadow: 0 3px 0 0 hsla(0, 0%, 51%, 0.3);
}

.nav-logo-block {
	width: 350px;
	margin-left: 2rem;
	text-decoration: none;
}

.nav-menu {
	float: none;
}

.search-reveal-mobile {
	display: none;
}

.search-reveal {
	height: 20px;
	width: 20px;
	background-image: url("/20160710165515/assets/images/search-sprite-desktop.svg");
	background-size: 20px 40px;
	background-position: 50% 0;
	background-repeat: no-repeat;
	cursor: pointer;
	transition: none;
	margin: 0 12px 13px 10px;
}

.search-reveal.search-reveal-desktop.active,
.search-reveal:hover {
	background-position: 50% 100%;
}

.nav-link,
.nav-link:visited {
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.75rem 0.7rem 0.4rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #eeece7;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 600;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.nav-link:hover {
	padding-bottom: 0.4rem;
	color: #fff;
	text-decoration: none;
}

.nav-link.w--current,
.nav-link.w--current:visited {
	color: #eeece7;
	font-weight: 600;
	text-decoration: none;
}

.nav-link.w--current:hover {
	border-bottom-style: none;
	background-color: transparent;
	color: #fff;
}

.nav-link.w--open,
.nav-link.w--open:visited {
	border-bottom-color: #71a01e;
}

.nav-link.w--open:hover {
	text-decoration: none;
}

.nav-link.dropdown-toggle {
	text-align: center;
}

.nav-link.mobile-duplicate,
.nav-link.mobile-duplicate:visited {
	padding-right: 0.73rem;
	padding-left: 0.73rem;
	border-bottom-width: 0px;
	border-bottom-color: silver;
}

.nav-link.mobile-duplicate:hover {
	border-bottom-color: silver;
}

.nav-link.mobile-duplicate.w--current {
	color: #ee0008;
	font-weight: 600;
}

.nav-dropdown {
	display: none;
	font-weight: 600;
}

.nav-dropdown-icon {
	width: 1rem;
	height: 1rem;
}

.nav-menu-button {
	position: relative;
}

.nav-dropdown-list {
	display: none;
	padding: 0.625rem 0.75rem 0.75rem;
	background-color: #fff;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.nav-dropdown-list.w--open {
	display: none;
	margin-top: 1px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	background-color: #fff;
}

.nav-dropdown-link,
.nav-dropdown-link:visited {
	padding: 0.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.9rem;
	text-decoration: none;
}

.nav-dropdown-link:hover {
	background-color: #595959;
	color: #fff;
	text-decoration: none;
}

.nav-dropdown-link.last-dropdown-link {
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.page-section {
	position: relative;
}

.page-section.footer-section {
	padding-top: 0;
	background: linear-gradient(120deg, #0e1a07, #1d3c21);
	color: #abc2c9;
}

.page-section.content-box-section-1 {
	background: #c5cdc2;
	box-shadow: none;
}

.column {
	padding-right: 10px;
	padding-bottom: 2.5rem;
	padding-left: 10px;
}

.column.left-signoff-column {
	padding-bottom: 0px;
	padding-left: 20px;
}

.column.center-signoff-column {
	padding-bottom: 0px;
	text-align: center;
}

.column.row-1-column {
	background-color: transparent;
}

.breadcrumbs-list {
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.breadcrumbs-list-item {
	display: inline-block;
	margin-bottom: 0.25rem;
	font-size: 0.8rem;
	line-height: 1.4rem;
	margin-top: 0.25rem;
}

.breadcrumbs-list-divider {
	display: inline-block;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.split-50 {
	width: 50%;
	min-width: 0px;
	float: left;
}

.split-25 {
	width: 25%;
	min-width: 0px;
	float: left;
}

.inside-page-row {
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.inside-page-left-column {
	display: block;
	padding-top: 1.25rem;
}

.inside-page-content-column {
	padding-top: 0px;
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.left-nav-list.nested-left-nav-list {
	margin-top: 2px;
	margin-bottom: 0rem;
	margin-left: 20px;
	padding-bottom: 0rem;
	box-shadow: 0 -1px 0 0 hsla(0, 0%, 45%, 0.15);
}

.left-nav-list-item {
	margin-top: 1px;
	box-shadow: 0 1px 0 0 #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item {
	box-shadow: 0 1px 0 0 #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item.last-left-nav-list-item {
	box-shadow: none;
}

.left-nav-list-item-link,
.left-nav-list-item-link:visited {
	display: block;
	padding: 0.5rem 0.5rem 0.5rem 1.125rem;
	-webkit-transition: color 200ms ease, background-color 200ms ease;
	transition: color 200ms ease, background-color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #636861;
	font-size: 0.93rem;
	line-height: 1.33rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-list-item-link:hover {
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 0px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	color: #112b18;
	text-decoration: none;
}

.left-nav-list-item-link.w--current,
.left-nav-list-item-link.w--current:visited {
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 0px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	color: #112b18;
}

.left-nav-list-item-link.w--current:hover {
	text-decoration: none;
}

.left-nav-list-item-link.nested-left-nav-link,
.left-nav-list-item-link.nested-left-nav-link:visited {
	color: #636861;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.left-nav-list-item-link.nested-left-nav-link:hover,
.left-nav-list-item-link.nested-left-nav-link.active {
	color: #112b18;
	text-decoration: none;
}

.left-nav-list-item-link.nested-left-nav-link.w--current {
	color: #112b18;
	font-weight: 600;
}

.inside-page-nested-row {
	padding-top: 0.5rem;
}

.inside-page-nested-right-column {
	padding-top: 1rem;
}

.inside-page-right-column-heading {
	margin-top: 0rem;
	color: #222;
	font-size: 1rem;
	line-height: 1.5rem;
}

.inside-page-right-col-item-wrap {
	margin-bottom: 1rem;
	margin-left: 20px;
	padding: 0.75rem 0.5rem 0.25rem;
	border-top: 2px solid #3169b4;
}

.column-content-wrap {
	margin-right: 10px;
	margin-bottom: 2rem;
	margin-left: 10px;
}

.column-content-wrap.social-media-wrap {
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	border-bottom: 1px solid gray;
	background: #2c4234;
	text-align: center;
	margin-left: 0px;
	margin-right: 0px;
}

.column-content-wrap.navigation-column {
	padding-right: 15px;
}

.footer-signoff-row {
	padding-bottom: 0.5rem;
}

.footer-grip-link,
.footer-grip-link:visited {
	width: 130px;
	height: 28px;
	min-width: 0px;
	padding-left: 32px;
	float: right;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #555;
	line-height: 0.625rem;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-grip-link:hover {
	color: #555;
	text-decoration: none;
}

.footer-signoff-text {
	display: inline-block;
	color: #888;
	text-align: center;
}

.footer-signoff-link,
.footer-signoff-link:visited {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #c6cec3;
	font-weight: 400;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #b8a06a;
	text-decoration: none;
}

.footer-signoff-link-wrap {
	display: block;
	margin-top: 0.25rem;
	margin-right: 1rem;
	margin-left: 1rem;
}

.footer-list-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.footer-social-icon-link,
.footer-social-icon-link:visited {
	width: 1.75rem;
	height: 1.75rem;
	min-width: 0px;
	margin: 0.5rem 0.5rem 0.25rem;
	float: none;
	background-color: #112b18;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.footer-social-icon-link:hover {
	background-color: #0d1911;
}

.footer-social-icon-image {
	float: none;
	height: 20px;
}

.header-container.w-container {
	position: relative;
}

.header-search-wrap {
	position: absolute;
	top: 100%;
	right: 0px;
	overflow: hidden;
	width: 500px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #d3d3d3;
	display: none;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-search-field {
	display: inline-block;
	width: 75%;
	height: 50px;
	margin-right: 0%;
	margin-bottom: 0px;
	border: 0px solid #000;
	border-radius: 0px;
}

.header-search-field:focus {
	border-color: transparent;
	box-shadow: inset 0 0 0 0 #000;
}

.header-search-session-message {
	width: 100%;
	height: 2px;
	max-height: 2px;
	max-width: 100%;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
}

.footer-column-title {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.left-nav-wrap {
	margin-top: 0rem;
	padding-right: 15px;
}

.footer-list-link,
.footer-list-link:visited {
	display: block;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-family: 'Open Sans', sans-serif;
	color: #c6cec3;
	font-size: 0.95rem;
	font-weight: 400;
}

.footer-list-link:hover {
	color: #ffffff;
	text-decoration: none;
}

.footer-list-link.w--current {
	color: #ffffff;
	text-decoration: underline;
}

.header-nav-bar-content-wrap {
	float: right;
	display: flex;
	align-items: flex-end;
	margin-top: -30px;
}

.header-search-form-wrap {
	position: relative;
	margin-bottom: 0px;
	padding: 1rem;
}

.footer-signoff-bar {
	margin-top: 0.5rem;
	padding-top: 1rem;
	padding-bottom: 0rem;
	background-color: #0d1911;
	font-size: 0.75rem;
	line-height: 1.25rem;
}

.cb-row-2 {
	color: #636861;
}

.content-box-title {
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #815f25;
	font-size: 1.75rem;
	line-height: 2.3rem;
	font-weight: 600;
	letter-spacing: -0.03rem;
}

.cb-row-2 .content-box-title {
	color: #112b18;
}

.wide-background {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 10px;
}

.wide-background.wide-background-row-1 {
	max-width: 1300px;
	padding-top: 2.25rem;
	padding-bottom: 0rem;
	background-color: hsla(0, 0%, 100%, 0.1);
}

.wide-background.wide-background-inside-page {
	padding: 0rem 40px;
	background-color: #fff;
}

.logo-by-line {
	color: #888;
}

.news-abstract {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.news-feed-article-heading {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.news-feed-date {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #474848;
	font-size: 0.8rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.footer-map-link,
.footer-map-link:visited {
	padding-left: 25px;
	background-image: url('/20150114134215/assets/images/fontawesome-map-pin-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #c6cec3;
	font-size: 0.95rem;
	font-weight: 400;
}

.footer-map-link:hover {
	color: #ffffff;
	text-decoration: none;
}

.footer-paragraph {
	font-family: 'Open Sans', sans-serif;
	color: #c6cec3;
	font-weight: 400;
}

.footer-paragraph.fax-text {
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/printer-fax-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
}

.email-link,
.email-link:visited {
	display: block;
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/envelope-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #c6cec3;
	font-weight: 400;
}

.email-link:hover {
	color: #ffffff;
	text-decoration: none;
}

.phone-link,
.phone-link:visited {
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/fontawesome-phone-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #c6cec3;
	font-weight: 400;
}

.phone-link:hover {
	color: #ffffff;
	text-decoration: none;
}

.social-icons-wrap.social-icons-wrap-header {
	display: none;
	margin-right: 0rem;
	margin-bottom: 0.25rem;
	float: right;
}

.newsletter-signup-button,
.newsletter-signup-button:visited {
	display: inline-block;
	height: 30px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 2px 1.5rem 2px 1rem;
	float: right;
	border: 1px solid #c5cdc2;
	background-color: #c5cdc2;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.85rem;
	font-weight: 600;
	color: #000000;
}

.newsletter-signup-button:hover {
	padding-right: 24px;
	background-color: #2c4234;
	color: #c5cdc2;
	text-decoration: none;
	border: 1px solid #c5cdc2;
}

.newsletter-div {
	position: relative;
	margin-right: 0.5rem;
	margin-left: 1.5rem;
	float: right;
}

.slider-widget {
	height: 550px;
	background-color: transparent;
}

.slider-mask {
	height: 100%;
}

.slide-1 {
	background-image: none;
}

.slider-caption-div {
	position: relative;
	top: 50%;
	right: 0px;
	bottom: 0px;
	max-height: 298px;
	max-width: 45%;
	min-height: 128px;
	min-width: 45%;
	padding: 1.5rem 2.25rem 3rem;
	float: right;
	background-color: hsla(0, 0%, 100%, 0.88);
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slider-header {
	margin-top: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #3169b4;
	line-height: 2.8rem;
	letter-spacing: -0.03rem;
}

.slider-container {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	display: block;
	height: 100%;
}

.slider-button {
	position: relative;
	left: 50%;
	display: none;
	background-color: #ed1c24;
	-webkit-transform: translate(-50%, 0px);
	-ms-transform: translate(-50%, 0px);
	transform: translate(-50%, 0px);
	font-weight: 600;
	text-transform: uppercase;
}

.more-text-arrow-link,
.more-text-arrow-link:visited {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 10px;
	padding-right: 1rem;
	background-position: 100% 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	color: #3d6740;
	font-size: 0.92rem;
	font-weight: 700;
	text-transform: uppercase;
}

.cb-boxes .more-text-arrow-link:hover {
	color: #815f25;
	text-decoration: none;
}

.more-text-arrow-link.more-text-arrow-link-slider,
.more-text-arrow-link.more-text-arrow-link-slider:visited {
	left: auto;
	right: 10%;
	bottom: 0px;
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	float: right;
	color: #3d6740;
	font-weight: 700;
}

.more-text-arrow-link.more-text-arrow-link-slider:hover {
	color: #815f25;
	text-decoration: none;
}

.more-text-arrow-link.more-row-1,
.more-text-arrow-link.more-row-1:visited {
	left: 10px;
	margin-bottom: 1rem;
	padding-right: 1.125rem;
	font-family: 'Open Sans', sans-serif;
	color: #3d6740;
	font-size: 0.92rem;
	font-weight: 700;
}

.more-text-arrow-link.more-row-1:hover {
	color: #815f25;
	text-decoration: none;
}

.slider-arrow-link-bar,
.slider-arrow-link-bar:visited {
	height: 40px;
}

.slider-arrow-link-bar:hover {
	color: #eeece7;
}

.slider-arrow-link-bar.slide-arrow-left {
	left: auto;
	top: auto;
	bottom: 0px;
	font-size: 1.75rem;
}

.slider-arrow-link-bar.slide-arrow-right {
	left: auto;
	top: auto;
	font-size: 1.75rem;
}

.content-box-paragraph {
	clear: both;
	font-size: 1rem;
}

.slide-nav {
	padding-top: 13px;
	background-color: hsla(0, 0%, 100%, 0.2);
	font-size: 0.8rem;
}

.slider-image-div-block {
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.right-signoff-column {
	padding-right: 0px;
}

.back-to-top-div {
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-top: 1px solid #363636;
	border-bottom: 1px none grey;
	background-color: #112b18;
	color: #fff;
	text-align: center;
}

.back-to-top-link,
.back-to-top-link:visited {
	display: inline;
	padding-right: 26px;
	background-image: url('/20150114134215/assets/images/arrow-medium-up-white.svg');
	background-position: 100% 50%;
	background-size: 14px;
	background-repeat: no-repeat;
	color: #c6cec3;
	font-size: 1rem;
	font-weight: 400;
}

.back-to-top-link:hover {
	background-image: url('/20150114134215/assets/images/arrow-medium-up-white.svg');
	background-position: 100% 50%;
	background-size: 14px;
	background-repeat: no-repeat;
	opacity: 1;
	color: #b8a06a;
	text-decoration: none;
}

.row-1-column {
	padding-bottom: 2.5rem;
	background-color: #fff;
}

.colored-content-box {
	position: relative;
	padding: 0.5rem 20px 2.75rem;
	border-top: 4px solid #89997c;
	background-color: #fff;
	width: 100%;
	color: #636861;
}

.no-sidebars-wrap {
	padding: 1.5rem 120px 2rem;
}

.field-label {
	color: #000;
	font-size: 0.95rem;
}

.text-field {
	font-size: 0.87rem;
}

.session-text {
	color: #000;
	font-weight: 600;
	text-align: left;
}

.session-text.error-message {
	padding: 20px;
	background-color: #fcc;
}

.session-text.success-message {
	background-color: #9eeb98;
}

.breadcrumb-link,
.breadcrumb-link:visited {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #797676;
	font-weight: 400;
	text-decoration: underline;
}

.breadcrumb-link:hover {
	color: #aaa;
	text-decoration: none;
}

.breadcrumb-link.w--current,
.breadcrumb-link.w--current:visited {
	color: #4484c3;
}

.breadcrumb-link.w--current:hover {
	text-decoration: none;
}

.breadcrumb-link#latestBread {
	color: #797676;
	text-decoration: none;
}

.text-link,
.text-link:visited {
	border-bottom: 1px solid silver;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #3169b4;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #aaa;
	text-decoration: none;
}

.current-page {
	color: #797676;
	font-weight: 400;
}

.h1-link,
.h1-link:visited {
	border-bottom: 1px solid silver;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #629900;
}

.h1-link:hover {
	border-bottom-color: transparent;
	color: #aaa;
	text-decoration: none;
}

.heading-link,
.heading-link:visited {
	border-bottom: 1px solid silver;
	color: #3169b4;
	font-weight: 700;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #aaa;
	text-decoration: none;
}

.dropdown-list {
	display: none;
	padding-top: 6px;
}

.dropdown-list.w--open {
	padding: 6px;
	background-color: #fff;
	box-shadow: -2px 2px 4px 0 rgba(41, 41, 41, 0.2), 2px 2px 4px 0 rgba(41, 41, 41, 0.2);
}

.dropdown-link,
.dropdown-link:visited {
	margin-top: 1px;
	padding-right: 10px;
	padding-left: 10px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #222;
	font-size: 0.9rem;
	font-weight: 400;
}

.dropdown-link:hover {
	background-color: #3169b4;
	color: #fff;
	text-decoration: none;
}

.dropdown-link.w--current {
	margin-bottom: 0px;
	background-color: #3169b4;
	color: #fff;
}

.nav-link-dd-toggle,
.nav-link-dd-toggle:visited {
	margin-right: 0px;
	margin-left: 0px;
	padding: 0rem 0rem 0rem 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #565656;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
	border-bottom: 6px solid transparent;
}

.nav-link-dd-toggle:hover {
	padding-bottom: 0rem;
	border-bottom: 6px solid #b8a06a;
}

.nav-link-dd-toggle.w--current,
.nav-link-dd-toggle.w--current:visited {
	border-bottom-color: #b8a06a;
	color: #565656;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-dd-toggle.w--current:hover {
	border-bottom-color: #b8a06a;
	background-color: transparent;
	color: #000;
}

.active-li,
.active-li:visited {
	border-bottom: 6px solid #b8a06a;
}

.active-item,
.active-item:visited {
	color: #eeece7;
}

.nav-link-dd-toggle.w--open:hover {
	text-decoration: none;
}

.nav-link-no-subpages,
.nav-link-no-subpages:visited {
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.75rem 0.5rem 0.4rem;
	border-bottom: 6px solid rgba(237, 28, 36, 0);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #565656;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}

.nav-link-no-subpages:hover {
	padding-bottom: 0.4rem;
	border-bottom: 6px solid #71a01e;
	background-color: transparent;
	color: #000;
	text-decoration: none;
}

.nav-link-no-subpages.w--current,
.nav-link-no-subpages.w--current:visited {
	border-bottom-color: #71a01e;
	color: #000;
	font-weight: 600;
	text-decoration: none;
}

.nav-link-no-subpages.w--current:hover {
	border-bottom-color: #71a01e;
	background-color: transparent;
	color: #000;
}

.nav-link-no-subpages.w--open,
.nav-link-no-subpages.w--open:visited {
	border-bottom-color: #71a01e;
}

.nav-link-no-subpages.w--open:hover {
	text-decoration: none;
}

.unordered-list-2 {
	box-shadow: 0 -2px 0 0 #89997c;
}

.ecommerce-links {
	display: block;
	margin-top: 15px;
	margin-right: 0.25rem;
	float: right;
	min-width: 160px;
}

.ecommercelinkimg {
	width: 1em;
	height: 1em;
	margin-right: 0.5em;
	float: left;
	opacity: 0.35;
}

.ecommercelinktext {
	margin-top: 1px;
	float: left;
	font-size: 0.825rem;
	line-height: 0.825rem;
	font-weight: 600;
	text-transform: uppercase;
}

.ecommerce-link-block,
.ecommerce-link-block:visited {
	margin-left: 1.5rem;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
	color: #565656;
}

.ecommerce-link-block:hover {
	color: #000;
}

.top-nav-wrap {
	float: right;
}

.logo-image {
	width: 100%;
}

.screen-message-wrapper {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border: 2px solid #000;
	background-color: #fff;
}

.screen-message-wrapper.success {
	border-width: 2px;
	border-color: #48a700;
	background-color: #bbee94;
}

.screen-message-wrapper.error {
	border-color: #ff0014;
	background-color: #ffccd0;
}

.right-side-col-wrapper {
	padding-left: 1.25rem;
}

.right-side-col-wrapper h4 {
	margin-top: 1.25rem;
	padding-top: 0.75rem;
	color: #222;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-side-col-wrapper h2 {
	margin-top: 0rem;
	padding-top: 0.75rem;
	color: #222;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-side-col-wrapper h3 {
	margin-top: 1.25rem;
	padding-top: 0.75rem;
	color: #222;
	font-size: 1rem;
}

.right-side-col-wrapper p {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

html.w-mod-js *[data-ix="mobile-dropdown"] {
	display: none;
}

@media (max-width: 991px) {
	.nav-logo-block {
		width: 300px;
		margin-left: 2.88rem;
	}

	.nav-menu {
		width: 100%;
		margin-top: 80px;
		margin-bottom: 0px;
		padding-top: 0rem;
		padding-bottom: 5px;
		border-top: 3px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		background-color: #3169b4;
	}

	.nav-link {
		width: 100%;
		height: 50px;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link:hover {
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
		background-color: #222;
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current {
		border-bottom-style: none;
		background-color: #000;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.35);
		color: #fff;
	}

	.nav-link.dropdown-toggle {
		width: 20%;
		float: right;
	}

	.nav-link.mobile-duplicate {
		padding-left: 1.25rem;
		background-image: url('/20150114134215/assets/images/icon_arrow2_right_wht.svg');
		background-position: 96% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
	}

	.nav-link.mobile-duplicate:hover {
		border-bottom-color: rgba(238, 0, 8, 0);
	}

	.nav-menu-button,
	.nav-menu-button:visited {
		width: 4.5rem;
		height: 5rem;
		margin-left: 1rem;
		padding: 0px;
		float: none;
		background-color: transparent;
		text-align: center;
	}

	.nav-menu-button:hover {
		background-color: #2c4234;
		color: #fff;
	}

	.nav-menu-button.w--open {
		background-color: #3169b4;
	}

	.nav-dropdown-list {
		display: none;
		margin-bottom: 0.75rem;
		padding: 0rem;
		float: left;
		background-color: gray;
		box-shadow: none;
	}

	.nav-dropdown-list.w--open {
		padding: 0rem 0rem 5px;
		border-top: 2px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		background-color: rgba(0, 0, 0, 0.2);
	}

	.nav-dropdown-link {
		padding: 0.75rem 1rem;
		border-top: 0px none transparent;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		color: #fff;
	}

	.nav-dropdown-link:hover {
		background-color: rgba(0, 0, 0, 0.6);
		text-decoration: none;
	}

	.search-reveal-desktop {
		display: none;
	}

	.search-reveal-mobile {
		display: block;
	}

	.search-reveal.search-reveal-mobile {
		margin-bottom: 0;
		margin-right: 0;
		background-image: none;
		width: 4.5rem;
		height: 5rem;
		display: flex;
		align-items: center;
		margin-left: 0;
	}

	.search-reveal.search-reveal-mobile .search-icon {
		background-image: url("/20160710165515/assets/images/search-sprite.svg");
		background-size: 20px 40px;
		background-position: 50% 0;
		background-repeat: no-repeat;
		width: 100%;
		display: inline-block;
		padding-top: 26px;
	}

	.search-reveal.search-reveal-mobile.active {
		background-color: #295794;
	}

	.search-reveal.search-reveal-mobile:hover {
		background-color: #3169b4;
	}

	.search-reveal.search-reveal-mobile.active .search-icon,
	.search-reveal.search-reveal-mobile:hover .search-icon {
		background-position: 50% 140%;
	}

	.page-section.footer-section {
		padding-top: 0;
	}

	.container.container-inside-page {
		padding-right: 10px;
		padding-left: 10px;
	}

	.cb-row.cb-row-1 {
		margin-bottom: 1rem;
	}

	.column.footer-column {
		padding-bottom: 1rem;
	}

	.column.left-signoff-column {
		padding-top: 0.75rem;
		padding-right: 20px;
		padding-bottom: 0.25rem;
		text-align: center;
	}

	.column.center-signoff-column {
		padding-bottom: 0.25rem;
	}

	.column.row-1-column {
		padding-right: 5px;
		padding-bottom: 1rem;
		padding-left: 5px;
	}

	.inside-page-row {
		padding-top: 0.25rem;
	}

	.inside-page-left-column {
		display: none;
	}

	.inside-page-content-column {
		padding-right: 0px;
	}

	.inside-page-nested-row {
		padding-top: 0.5rem;
	}

	.inside-page-nested-content-column {
		margin-bottom: 1rem;
		padding-bottom: 0rem;
	}

	.inside-page-right-col-item-wrap {
		margin-bottom: 0rem;
		margin-left: 0px;
	}

	.column-content-wrap.social-media-wrap {
		margin-right: 0px;
		margin-left: 0px;
	}

	.column-content-wrap.navigation-column {
		padding-right: 10%;
	}

	.footer-grip-link {
		float: none;
	}

	.footer-signoff-link-wrap {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.header-search-wrap {
		min-width: 100%;
		margin-bottom: 0rem;
	}

	.nav-menu-button-icon {
		margin-top: 1rem;
		font-size: 2rem;
		line-height: 2rem;
		color: #fff;
	}

	.nav-menu-button-text {
		font-size: 0.875rem;
		line-height: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		color: #fff;
	}

	.content-box-title {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.wide-background {
		padding-top: 1.75rem;
		padding-bottom: 2rem;
	}

	.wide-background.wide-background-row-1 {
		padding-top: 2rem;
	}

	.wide-background.wide-background-inside-page {
		max-width: 991px;
		margin-right: 1rem;
		margin-left: 1rem;
		padding-right: 30px;
		padding-bottom: 0rem;
		padding-left: 30px;
	}

	.news-abstract {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.news-feed-article-heading {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		display: none;
		margin-top: 0.75rem;
		margin-right: 0rem;
	}

	.newsletter-signup-button {
		float: none;
		background-image: none;
	}

	.newsletter-div {
		margin-top: 0.75rem;
		margin-right: 0rem;
		margin-left: 1rem;
	}

	.slider-widget {
		height: 500px;
	}

	.slider-caption-div {
		max-width: 50%;
		padding: 1.25rem 1.5rem 3rem;
	}

	.slider-header {
		margin-top: 0rem;
		font-size: 1.75rem;
		line-height: 2.15rem;
	}

	.slider-container {
		position: absolute;
	}

	.more-text-arrow-link.more-text-arrow-link-slider {
		right: 8%;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		line-height: 1.45rem;
	}

	.right-signoff-column {
		padding-right: 0px;
		text-align: center;
	}

	.back-to-top-link {
		background-size: auto 14px;
	}

	.colored-content-box {
		padding-right: 15px;
		padding-left: 15px;
	}

	.no-sidebars-wrap {
		margin-right: -10px;
		margin-left: -10px;
		padding-top: 0.25rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-nav-dd {
		display: block;
	}

	.nav-link-dd-toggle {
		width: 100%;
		height: 50px;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link-dd-toggle:hover {
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
		background-color: #222;
		box-shadow: none;
		color: #fff;
	}

	.nav-link-dd-toggle.w--current {
		border-bottom-style: none;
		background-color: #000;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link-dd-toggle.w--current:hover {
		background-color: rgba(0, 0, 0, 0.35);
		color: #fff;
	}

	.nav-link-no-subpages {
		width: 100%;
		height: 50px;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link-no-subpages:hover {
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
		background-color: #222;
		box-shadow: none;
		color: #fff;
	}

	.nav-link-no-subpages.w--current {
		border-bottom-style: none;
		background-color: #000;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link-no-subpages.w--current:hover {
		background-color: rgba(0, 0, 0, 0.35);
		color: #fff;
	}

	.acrobat-reader-link {
		margin-right: 0rem;
		font-size: 0.85rem;
		text-align: center;
	}

	.ecommerce-links {
		display: block;
		margin-top: 1.5rem;
		float: none;
		min-width: 0;
	}

	.ecommercelinkimg {
		width: 1.5em;
		height: 1.5em;
		margin-right: 0em;
		float: none;
	}

	.ecommercelinkimg:hover {
		opacity: 0.9;
	}

	.ecommercelinktext {
		display: none;
	}

	.top-nav-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.right-side-col-wrapper {
		padding-left: 0rem;
	}
}

@media (max-width: 767px) {
	h1 {
		margin-bottom: 0.75rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	.nav-logo-block {
		width: 260px;
		margin-left: 2.25rem;
	}

	.nav-menu-button,
	.nav-menu-button:visited,
	.search-reveal.search-reveal-mobile {
		width: 4rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.cb-row.cb-row-1 {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
		border-bottom: 1px none #000;
	}

	.column {
		padding: 0.75rem 20px 3.5rem;
		border-bottom: 1px solid silver;
	}

	.column.footer-column {
		padding-right: 10px;
		padding-left: 10px;
		border-bottom: 1px none #000;
	}

	.column.left-signoff-column {
		padding-bottom: 0.25rem;
		border-bottom: 1px none #000;
	}

	.column.center-signoff-column {
		padding-top: 0rem;
		padding-bottom: 0.25rem;
		border-bottom: 1px none #000;
	}

	.column.row-1-column {
		border-bottom: 1px none #000;
	}

	.inside-page-nested-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-nested-right-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.column-content-wrap.navigation-column {
		padding-right: 7%;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-grip-link {
		float: none;
	}

	.footer-signoff-text {
		display: block;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.footer-social-icon-link {
		margin-right: 1rem;
		margin-left: 0rem;
	}

	.wide-background {
		padding-top: 0.5rem;
		padding-right: 0px;
		padding-left: 0px;
		box-shadow: none;
	}

	.wide-background.wide-background-row-1 {
		padding-top: 1rem;
		padding-bottom: 1rem;
		box-shadow: none;
	}

	.wide-background.wide-background-inside-page {
		max-width: 767px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.news-abstract {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.news-feed-article-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		margin-left: 0.25rem;
		float: left;
	}

	.newsletter-div {
		margin-left: 20px;
		float: left;
		clear: left;
	}

	.slider-widget {
		height: 460px;
	}

	.slide-1 {
		height: 100%;
	}

	.slider-caption-div {
		position: absolute;
		left: 0px;
		top: auto;
		right: 0px;
		bottom: 0px;
		display: block;
		width: 100%;
		height: 240px;
		max-height: none;
		max-width: 100%;
		min-height: 0px;
		min-width: 0%;
		margin-right: auto;
		margin-left: auto;
		float: left;
		background-color: #fff;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.more-text-arrow-link {
		margin-bottom: 1.5rem;
		margin-left: 20px;
	}

	.more-text-arrow-link.more-row-1 {
		left: -5px;
	}

	.slider-arrow-link-bar.slide-arrow-left {
		left: 0px;
		bottom: 0px;
	}

	.content-box-paragraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slide-nav {
		left: 0px;
		top: 33px;
		right: 0px;
		display: block;
	}

	.slide-nav.slider-caption-paragraph {
		top: -58px;
		font-size: 0.8rem;
	}

	.slider-image-div-block {
		height: 300px;
	}

	.slider-caption-paragraph {
		font-size: 1rem;
	}

	.no-sidebars-wrap {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.65rem;
		line-height: 2rem;
	}

	h2 {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	h6 {
		color: #000;
	}

	p {
		color: #000;
	}

	ul {
		padding-left: 20px;
	}

	blockquote {
		padding-left: 15px;
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.nav-logo-block {
		display: block;
		width: 260px;
		margin: 0rem auto;
		padding-left: 0px;
		float: none;
	}

	.nav-menu {
		background-color: #3169b4;
	}

	.nav-link {
		font-size: 0.95rem;
	}

	.nav-link.w--current {
		font-size: 0.95rem;
	}

	.nav-menu-button {
		width: 50px;
		height: 50px;
		float: none;
	}

	.search-reveal.search-reveal-mobile {
		width: 50px;
		height: 50px;
	}

	.column {
		padding-right: 10px;
		padding-left: 10px;
		border-bottom-width: 1px;
		border-bottom-color: silver;
	}

	.column.footer-column {
		padding-top: 0.25rem;
	}

	.column.left-signoff-column {
		border-bottom: 1px none #000;
	}

	.column.center-signoff-column {
		padding-top: 0rem;
		border-bottom: 1px none #000;
	}

	.split-50 {
		width: 100%;
		margin-bottom: 1rem;
	}

	.split-25 {
		width: 100%;
		margin-bottom: 1rem;
	}

	.column-content-wrap {
		margin-bottom: 1rem;
	}

	.column-content-wrap.navigation-column {
		padding-right: 0%;
	}

	.header-container {
		width: 100%;
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.header-search-wrap {
		width: 100%;
	}

	.header-search-form {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.nav-menu-button-icon {
		margin-top: 0.5rem;
	}

	.nav-menu-button-text {
		display: none;
	}

	.footer-signoff-bar {
		margin-top: 0rem;
	}

	.wide-background.wide-background-inside-page {
		max-width: 479px;
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		margin-bottom: 0rem;
		float: none;
	}

	.newsletter-div {
		margin-top: 0rem;
		margin-left: 0.75rem;
		float: none;
	}

	.slider-widget {
		height: 506px;
	}

	.slide-2 {
		background-image: none;
	}

	.slider-caption-div {
		height: 286px;
		padding-right: 20px;
		padding-bottom: 3rem;
		padding-left: 20px;
	}

	.slider-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.more-text-arrow-link {
		margin-left: 10px;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.slide-nav {
		left: 0px;
		top: -92px;
		right: 0px;
	}

	.slide-nav.slider-caption-paragraph {
		top: -105px;
		font-size: 0.7rem;
	}

	.slider-image-div-block {
		height: 220px;
	}

	.slider-image-div-block.slide-2-image {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slider-caption-paragraph {
		max-height: 144px;
	}

	.no-sidebars-wrap {
		padding-right: 10px;
		padding-left: 10px;
	}

	.nav-link-dd-toggle {
		font-size: 0.95rem;
	}

	.nav-link-dd-toggle.w--current {
		font-size: 0.95rem;
	}

	.nav-link-no-subpages {
		font-size: 0.95rem;
	}

	.nav-link-no-subpages.w--current {
		font-size: 0.95rem;
	}

	.ecommerce-links {
		margin-top: 0rem;
		float: none;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.ecommercelinkimg {
		float: none;
	}

	.ecommercelinktext {
		float: none;
	}

	.top-nav-wrap {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px solid #d3d3d3;
	}

	.header-nav-bar-content-wrap {
		margin-top: 0px;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Webflow Styles *****************/
/*************************************************/
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}
/* --- Increase the max-width for full view --- */
@media screen and (min-width: 992px) {
	.w-container {
		max-width: 1200px;
	}
}
/* CHANGE THE STYLE OF THE SLIDER PAGINATION
.w-slider-nav>div{
border: white 1px solid;
background: "";
}
.w-slider-nav>div.w-active{
border: white 1px solid;
background-color: red;
}
---- */
/* HIDE SLIDER LEFT-RIGHT ARROWS AT DEFINED VIEWPORT WIDTH */
@media all and (max-width: 1300px) {
	.slider-arrow-link-bar {
		display: none;
	}
}
/* EQUAL HEIGHT HOMEPAGE CONTENT BOX COLUMNS - Row 1 */
@media screen and (min-width: 768px) {
	.row-1-column {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}
/* EQUAL HEIGHT HOMEPAGE CONTENT BOX COLUMNS - Row 2 */
@media screen and (min-width: 768px) {
	.cb-row {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}
/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	text-decoration: none;
}
/* Text selection - Mozilla based browsers */
::-moz-selection {
	background-color: #808080;
	color: #ffffff;
}
/* Text selection - Safari browser */
::selection {
	background-color: #808080;
	color: #ffffff;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.nav-link-dd-toggle.w--current .nav-link {
	color: #000;
}

.main-nav-dd {
	float: left;
}

.nav-menu .main-nav-dd:first-child .nav-link-dd-toggle a {
	letter-spacing: normal;
	margin-right: -2px;
}

/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: block !important;
	}

	.w-nav-button {
		display: block;
	}
}

.mm-opened .nav-menu-icon {
	color: white;
	background-color: #2c4234;
}

.mobile-navigation-menu {
	margin-top: 0px;
	background: #112b18;
}

.mobile-navigation-menu li {
	border-bottom: 1px solid #0d1911;
	margin-left: 2px;
}

.mobile-navigation-menu li:hover,
.mobile-navigation-menu li.active {
	background-color: #eeece7;
}

.mobile-navigation-menu li.active:hover {
	background-color: #eeece7;
}

.mobile-navigation-menu li a.w-nav-link.nav-a {
	color: #ffffff;
	font-size: 1rem;
	line-height: 1.5rem;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
	padding: 0.85rem 1rem;
}

.mobile-navigation-menu li:hover a.w-nav-link.nav-a,
.mobile-navigation-menu li.active a.w-nav-link.nav-a {
	color: #0d1911;
}

.mm-listview > li:not(.mm-divider)::after {
	border: none;
}

.mm-listview .mm-next {
	background: #89997c;
}

.mm-listview .mm-next::before {
	border-left: 0px;
}

.mm-menu .mm-listview > li .mm-next::after {
	border-color: white;
}

.mm-next::after,
.mm-prev::before {
	content: '';
	border-top: 3px solid transparent;
	border-top-color: transparent;
	border-left: 3px solid transparent;
	border-left-color: transparent;
	display: block;
	width: 12px;
	height: 12px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
}

.mm-menu .mm-btn::after,
.mm-menu .mm-btn::before {
	border-color: #fff;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: white;
	font-size: 1rem;
	line-height: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	text-align: left;
	padding-left: 4px;
	padding-top: 7px;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	background: transparent;
}

/***************** Homepage  **************************/
/******************************************************/
.content-box-title a {
	font-size: inherit;
	color: inherit;
	line-height: inherit;
}

.cb-boxes a:not(.more-text-arrow-link) {
	color: #3d6740;
	border-bottom: 1px solid silver;
	text-decoration: none;
}

.cb-boxes a:hover {
	border-bottom-color: transparent;
	color: #815f25;
	text-decoration: none;
}

.cb-boxes a[href^="mailto:"] {
	overflow-wrap: break-word;
}

/***************** Slideshow  **************************/
/******************************************************/
.more-text-arrow-link-slider {
	text-transform: uppercase;
}

.slider-caption-paragraph {
	color: #1e1f28;
}

.slider-caption-paragraph p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
}

.slider-caption-div h1 {
	margin-top: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #815f25;
	line-height: 2.8rem;
	letter-spacing: -0.03rem;
}

.more-text-arrow-link:after {
	content: "\e600";
	font-family: 'webflow-icons';
	font-size: 0.675rem;
	color: #3d6740;
}

@media (min-width: 767px) {
	.slider-widget .slider-container.emptyCaption {
		display: none;
	}
}

@media (max-width: 991px) {
	.slider-caption-div h1 {
		margin-top: 0rem;
		font-size: 1.75rem;
		line-height: 2.15rem;
	}
}

@media (max-width: 767px) {
	.slider-caption-div h1 {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.slider-widget.no-caption {
		height: 300px;
	}

	.slider-widget.no-caption .slide-nav.slider-caption-paragraph {
		top: auto;
	}
}

@media (max-width: 479px) {
	.slider-caption-div h1 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.slider-widget.no-caption {
		height: 220px;
	}

	.slider-widget.no-caption .slide-nav.slider-caption-paragraph {
		top: auto;
	}
}

/****************** Inside Page *******************/
/*************************************************/
.left-nav-list-item-link.active {
	background-image: url('/20150114134215/assets/images/angle-right.svg');
	background-position: 0px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	color: #112b18;
}

.no-columns.inside-page-row {
	padding: 1.5rem 120px 2rem;
	margin-left: 0px;
	margin-right: 0px;
	min-height: 700px;
}

.right-column .inside-page-content-column {
	padding-left: 10px;
}

.right-column .inside-page-nested-right-column {
	padding-top: 1rem;
	padding-left: 15px;
}

@media (max-width: 991px) {
	.no-columns.inside-page-row {
		margin-right: -10px;
		margin-left: -10px;
		padding-top: 0.25rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-column .inside-page-nested-right-column {
		padding-left: 0px;
	}
}

@media (max-width: 767px) {
	.no-columns.inside-page-row {
		padding-right: 10px;
		padding-left: 10px;
	}

	.right-column .inside-page-content-column {
		padding-left: 0px;
	}
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.inside-page-nested-right-column .box {
	border-top: 2px solid #89997c;
	margin-bottom: 0.75rem;
	padding-top: 0.75rem;
}

.inside-page-nested-right-column .box * {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.inside-page-nested-right-column .box h4,
.inside-page-nested-right-column .box h3,
#events_module.cms_list .cms_list_item .cms_date {
	margin-top: 0rem;
	padding-top: 0px;
	color: #112b18;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 0.5rem;
	font-weight: 700;
}

.inside-page-nested-right-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.inside-page-nested-right-column .box .contentbox_item_image {
	width: 24px;
}

.inside-page-nested-right-column .box.documents td:first-child {
	padding-right: 7px;
}

.inside-page-nested-right-column .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.inside-page-nested-right-column .box {
		margin-left: 0em;
	}
}

/****************** Footer ***********************/
/*************************************************/
.back-to-top-div {
	cursor: pointer;
}

.phone-link {
	display: none;
}

.phone-link.no-link {
	display: block;
	color: #c6cec3;
}

.footer-section a[href^=tel] {
	color: #c6cec3;
}

.phone-link.no-link:hover {
	color: #c6cec3;
}

#GRIPFooterLogo {
	line-height: 11px;
	display: inline-block;
	float: right;
	padding-top: 0px !important;
}

.column-content-wrap.logos {
	text-align: center;
	display: flex;
	justify-content: center;
}

.column-content-wrap.navigation-column .phone-link,
.column-content-wrap.navigation-column .fax-text,
.column-content-wrap.navigation-column .footer-map-link {
	white-space: nowrap;
}

@media (min-width: 991px) {
	.footer-section.page-section .cb-row,
	.footer-section.page-section .footer-signoff-row {
		width: 100%;
	}
}

@media (min-width: 767px) {
	.column-content-wrap.logos {
		margin-top: 70px;
	}
}

@media (max-width: 991px) {
	.phone-link {
		display: block;
	}

	.phone-link.no-link {
		display: none;
	}

	#GRIPFooterLogo {
		float: none;
		margin-right: 0px;
		margin-top: 4px;
	}
}

@media (max-width: 767px) {
	.column-content-wrap.logos {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.column-content-wrap.logos {
		text-align: center;
	}
}


/****************** Inside Page Styles ***********************/
/*************************************************/
/* heading links */
.inside-page-row h1 a,
.inside-page-row h2 a,
.inside-page-row h3 a,
.inside-page-row h4 a,
.inside-page-row h5 a,
.inside-page-row h6 a,
.inside-page-row h1 a:visited,
.inside-page-row h2 a:visited,
.inside-page-row h3 a:visited,
.inside-page-row h4 a:visited,
.inside-page-row h5 a:visited,
.inside-page-row h6 a:visited {
	border-bottom: 1px solid silver;
	color: inherit;
}

.inside-page-row h1 a:hover,
.inside-page-row h2 a:hover,
.inside-page-row h3 a:hover,
.inside-page-row h4 a:hover,
.inside-page-row h5 a:hover,
.inside-page-row h6 a:hover {
	border-bottom-color: transparent;
	color: #815f25;
	text-decoration: none;
}

/* links */
.inside-page-nested-row a,
.left-sidebar a,
.inside-page-nested-row a:visited,
.left-sidebar a:visited {
	color: #3d6740;
	border-bottom: 1px solid silver;
}

.inside-page-nested-row a:hover,
.left-sidebar a:hover {
	border-bottom-color: transparent;
	color: #815f25;
	text-decoration: none;
}

/* buttons */
.inside-page-nested-row .button,
.inside-page-nested-row .primary,
.inside-page-nested-row .secondary,
.inside-page-nested-row .tertiary,
.ui-form-buttons button[type="submit"],
.inside-page-nested-row button[type="submit"],
.cms_form_button.primary,
.cms_form_button.secondary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.cb-boxes a.primary,
.cb-boxes a.primary,
.inside-page-nested-row a.button:visited,
.inside-page-nested-row a.primary:visited,
.inside-page-nested-row a.secondary:visited,
.inside-page-nested-row a.tertiary:visited {
	padding: 0.375rem 1rem !important;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	text-decoration: none;
	cursor: pointer;
	-webkit-appearance: none;
	border: 0px !important;
	border-radius: 0px;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
}

.inside-page-nested-row .primary,
.ui-form-buttons button[type="submit"],
.inside-page-nested-row button[type="submit"],
.cms_form_button.primary,
.cb-boxes .primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.inside-page-nested-row a.primary:visited {
	background-color: #c5cdc2 !important;
	color: #142b1a !important;
}

.inside-page-nested-row .button:hover,
.inside-page-nested-row .primary:hover,
.ui-form-buttons button[type="submit"]:hover,
.inside-page-nested-row button[type="submit"]:hover,
.inside-page-nested-row .primary:hover,
.cb-boxes .primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover {
	background-color: #142b1a !important;
	color: #c5cdc2 !important;
}


.inside-page-nested-row .secondary,
.inside-page-nested-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-nested-row .secondary:visited {
	background-color: #142b1a !important;
	color: #c5cdc2 !important;
}

.inside-page-nested-row .secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #c5cdc2 !important;
	color: #142b1a !important;
}

.inside-page-nested-row .tertiary,
.inside-page-nested-row button[type="submit"].tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary,
.inside-page-nested-row a.tertiary:visited {
	background-color: #3d6740 !important;
	color: #fff !important;
}

.inside-page-nested-row .tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #0d1911 !important;
	color: #fff !important;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: #3d6740;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border: 2px solid #3d6740;
	color: #fff;
}

#message.error,
#polls .session_message.error {
	background-color: #783031;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border: 2px solid #783031;
	color: #fff;
}

#message.success *,
#message.error *,
#polls .session_message.success *,
#polls .session_message.error * {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
	border-bottom: 1px solid white;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #0d1911;
	color: white;
}

table.styled th {
	font-weight: 600;
	font-size: 0.92rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #3d6740;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: #fff;
	text-decoration: underline;
	border-bottom: 0px;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #cecece;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-row input[type="text"],
.inside-page-row input[type="tel"],
.inside-page-row input[type="password"],
.inside-page-row input[type="email"],
.inside-page-row select,
.inside-page-row textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.inside-page-row textarea {
	resize: vertical;
}

.inside-page-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-row input[type="text"],
	.inside-page-row input[type="tel"],
	.inside-page-row input[type="password"],
	.inside-page-row input[type="email"],
	.inside-page-row select,
	.inside-page-row textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: #cee2ee;
	border: 0px;
	color: #cee2ee;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.375rem 1rem !important;
	box-shadow: 0 0 5px #000;
}

/*keyboard accessible main nav drop-downs*/
.nav-link-dd-toggle .dropdown-list {
	padding: 6px;
	background-color: #eeece7;
	box-shadow: -2px 2px 4px 0 rgba(41, 41, 41, 0.2), 2px 2px 4px 0 rgba(41, 41, 41, 0.2);
	list-style-type: none;
	margin-top: 6px;
}

.nav-link-dd-toggle.open .dropdown-list {
	display: block;
}

.container-wrapper .dropdown-list li a {
	margin-top: 1px;
	padding: 10px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #636861;
	font-size: 0.9rem;
	font-weight: 400;
	display: block;
	text-transform: none;
}

.container-wrapper .dropdown-list li a.w--current,
.container-wrapper .dropdown-list li a:hover {
	color: #112b18;
	background-color: #c5cdc2;
	text-decoration: none;
}
